<template>
    <transition name="fade">
        <div v-show="show" style="display:none;" id="product-nutrition-dialog" aria-label="dialog">
            <div class="nutrition-overlay overlay" @click="hideDialog"></div>
            <div class="nutrition-content dialog-content" style="position:relative;">
                <button class="icon" @click="hideDialog" style="position:absolute;right:5px;top:5px;">x</button>
                <div class="v-card--text">
                    <div class="pull-left image-container" v-if="show">
                        <img :src="selectedProduct.image" class="product-image contain" />
                    </div>
                    <div class="one-half pull-left">
							<h2 class="product-title" v-if="show">
								{{ selectedProduct.name }}
							</h2>
							<p class="product-ingredients" v-if="show">
								{{ selectedProduct.ingredients }}
							</p>
							<div>
								<img src="/wp-content/uploads/2020/05/GFGrayCLAIMS2.svg" class="product-attribute-icon" 
                                    v-if="selectedProduct.isGlutenFree" height="60" width="60" alt="Gluten Free" />
								<img src="/wp-content/uploads/2020/05/GMOGrayCLAIMS2.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isGmoFree" alt="GMO Free" height="60" width="60" />
								<img src="/wp-content/uploads/2020/05/PresGrayCLAIMS2.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isNoPreservatives" alt="No Preservatives" height="60" width="60" />
								<img src="/wp-content/uploads/2020/05/ArtiGrayCLAIMS2.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isNoArtificialIngredients" alt="No Artificial Ingredients" height="60" width="60" />
								<img src="/wp-content/uploads/2020/05/LS-GRAYCLAIMS3.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isLowSodium" alt="Low Sodium" height="60" width="60" />
								<img src="/wp-content/uploads/2020/05/ORGANICGRAYCLAIMS3.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isOrganic" alt="Organic" height="60" width="60" />
								<img src="/wp-content/uploads/2020/05/VEGAN-GRAYCLAIMS3.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isVegan" alt="Vegan" height="60" width="60" />
								<img src="/wp-content/uploads/2020/05/KOSHER-GRAYCLAIMS3.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isKosher" alt="Kosher" height="60" width="60" />
								<img src="/wp-content/uploads/2021/09/Keto-grey-button-01.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isKeto" alt="Keto" height="60" width="60" />
								<img src="/wp-content/uploads/2021/09/Paleo-grey-button-01.svg" 
                                    class="product-attribute-icon" v-if="selectedProduct.isPaleo" alt="Paleo" height="60" width="60" />
							</div>
						</div>
                </div>
                
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    data() {
        return {
            selectedProduct: {},
            show: false,
            categoryKeyColors: {
                'VGTN': 'rgb(141,154,77)',
                'VGN': '#F89C1B',
                'GFi': 'rgb(76,47,28)',
                'GF': 'rgb(76,47,28)',
                'DF': 'rgb(207,201,198)'
            },
            categoryKeyLabels: {
                'VGTN': 'Vegetarian',
                'VGN': 'Vegan',
                'GFi': 'Gluten-Free Ingredients',
                'DF': 'Dairy-Free'
            }
        }
    },
    computed: {
        categoryKeys() {
            
            return [];
        }
    },
    mounted() {
        console.log(this);
        window.eventBus.$on('setProduct', (data) => {
            this.show = true;
            this.selectedProduct = data;
        });
    },
    methods: {
        hideDialog() {
            this.show = false;
            document.querySelector('body').classList.remove('dialog-visible');
        }
    }
}
</script>