var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        staticStyle: { display: "none" },
        attrs: { id: "product-nutrition-dialog", "aria-label": "dialog" }
      },
      [
        _c("div", {
          staticClass: "nutrition-overlay overlay",
          on: { click: _vm.hideDialog }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "nutrition-content dialog-content",
            staticStyle: { position: "relative" }
          },
          [
            _c(
              "button",
              {
                staticClass: "icon",
                staticStyle: { position: "absolute", right: "5px", top: "5px" },
                on: { click: _vm.hideDialog }
              },
              [_vm._v("x")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "v-card--text" }, [
              _vm.show
                ? _c("div", { staticClass: "pull-left image-container" }, [
                    _c("img", {
                      staticClass: "product-image contain",
                      attrs: { src: _vm.selectedProduct.image }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "one-half pull-left" }, [
                _vm.show
                  ? _c("h2", { staticClass: "product-title" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.selectedProduct.name) +
                          "\n\t\t\t\t\t\t\t"
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.show
                  ? _c("p", { staticClass: "product-ingredients" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.selectedProduct.ingredients) +
                          "\n\t\t\t\t\t\t\t"
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm.selectedProduct.isGlutenFree
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src: "/wp-content/uploads/2020/05/GFGrayCLAIMS2.svg",
                          height: "60",
                          width: "60",
                          alt: "Gluten Free"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isGmoFree
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src: "/wp-content/uploads/2020/05/GMOGrayCLAIMS2.svg",
                          alt: "GMO Free",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isNoPreservatives
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2020/05/PresGrayCLAIMS2.svg",
                          alt: "No Preservatives",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isNoArtificialIngredients
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2020/05/ArtiGrayCLAIMS2.svg",
                          alt: "No Artificial Ingredients",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isLowSodium
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src: "/wp-content/uploads/2020/05/LS-GRAYCLAIMS3.svg",
                          alt: "Low Sodium",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isOrganic
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2020/05/ORGANICGRAYCLAIMS3.svg",
                          alt: "Organic",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isVegan
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2020/05/VEGAN-GRAYCLAIMS3.svg",
                          alt: "Vegan",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isKosher
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2020/05/KOSHER-GRAYCLAIMS3.svg",
                          alt: "Kosher",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isKeto
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2021/09/Keto-grey-button-01.svg",
                          alt: "Keto",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedProduct.isPaleo
                    ? _c("img", {
                        staticClass: "product-attribute-icon",
                        attrs: {
                          src:
                            "/wp-content/uploads/2021/09/Paleo-grey-button-01.svg",
                          alt: "Paleo",
                          height: "60",
                          width: "60"
                        }
                      })
                    : _vm._e()
                ])
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }